import { useAccountStore } from "@/utils";
import { API } from "@/utils/utility";
import { queryOptions, useQuery } from "@tanstack/react-query";

type MyClaimsResponse = {
  previousPage: number;
  currentPage: number;
  nextPage: number;
  last: boolean;
  totalPages: number;
  totalItems: number;
  totalItemsPage: number;
  items: {
    playerType: string;
    ispb: string;
    claim: {
      key: string;
      keyType: string;
      claimStatus: string;
      dateKeyClaimed: string;
      lastModified: string;
      grantorDeadline: string;
      claimDeadline: string;
      claimUUID: string;
    };
  }[];
};

async function listClaims() {
  const { data } = await API.pix.get<MyClaimsResponse>(
    "/api/v1/pix/Claims/find",
  );
  return data;
}

export const pixQueryFactory = {
  default: ["pix"] as const,
  listClaims: (accountId: string) =>
    queryOptions({
      queryKey: [...pixQueryFactory.default, "claims", accountId],
      queryFn: listClaims,
      refetchOnWindowFocus: true,
    }),
};

export function useClaimsList() {
  const { currentAccountId } = useAccountStore();
  return useQuery(pixQueryFactory.listClaims(currentAccountId));
}
