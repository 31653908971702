export const PF_PJ_MAX_KEYS = 4;

export const claimTypes = {
  PORTABILITY: "PORTABILITY",
  KEY_TRANSFER: "KEY_TRANSFER",
} as const;

export const pixKeyDefinitions = [
  {
    title: "E-mail",
    type: "Email",
    icon: "GeralMail",
  },
  {
    title: "Celular",
    type: "PhoneNumber",
    icon: "GeralPhone",
  },
  {
    title: "CNPJ",
    type: "CNPJ",
    icon: "GeralStore",
  },
  {
    title: "CPF",
    type: "NationalRegistration",
    icon: "GeralUserPerson",
  },
  {
    title: "Chave Aleatória",
    type: "EVP",
    icon: "GeralKey",
  },
  {
    title: "Código Pix",
    type: "EVP",
    icon: "MoneyPix",
  },
] as const;

export const pixKeyMap = pixKeyDefinitions.reduce(
  (acc, { type, title, icon }) => {
    acc[type] = { title, icon };
    return acc;
  },
  {} as Record<
    (typeof pixKeyDefinitions)[number]["type"],
    { title: string; icon: string }
  >,
);

type PixKeyDefinition = (typeof pixKeyDefinitions)[number];
export type PixKeyType = PixKeyDefinition["type"];
export type PixKeyTitle = PixKeyDefinition["title"];

export const keyTitle: Record<PixKeyType, string> = Object.fromEntries(
  Object.entries(pixKeyMap).map(([type, { title }]) => [type, title]),
) as Record<PixKeyType, string>;

export const keyIcon: Record<PixKeyType, string> = Object.fromEntries(
  Object.entries(pixKeyMap).map(([type, { icon }]) => [type, icon]),
) as Record<PixKeyType, string>;

export function getPixKeyTypeByTitle(title: string): PixKeyType | undefined {
  return pixKeyDefinitions.find((item) => item.title === title)?.type;
}

export function getPixKeyTitleByType(
  type: PixKeyType,
): PixKeyTitle | undefined {
  return pixKeyDefinitions.find((item) => item.type === type)?.title;
}

export function getPixKeyIconByType(type: PixKeyType): string | undefined {
  return pixKeyDefinitions.find((item) => item.type === type)?.icon;
}

export function getPixKeyDefinitionByType(
  type: PixKeyType,
): PixKeyDefinition | undefined {
  return pixKeyDefinitions.find((item) => item.type === type);
}

export function getPixKeyDefinitionByTitle(
  title: string,
): PixKeyDefinition | undefined {
  return pixKeyDefinitions.find((item) => item.title === title);
}

export const tokenRequestClaimGuid = "09E8FC99-5B84-4ED4-B2E1-5EF4CC1D6469";
export const tokenRequestGuid = "63D07325-4980-4ED9-9F6B-D7CFC985BA3F";
export const confirmPixClaimId = "FB194064-CD29-49BD-8F88-66E052E559BF";
export const rejectPixClaimId = "EDD1045F-188D-4D46-B619-A4729B8D2716";
export const createPixClaimId = "7E343844-494B-443B-8828-B9A8C2F39EDC";
