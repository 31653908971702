import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";

export function TransactionsList() {
  return (
    <Parcel
      config={() => System.import("@hyperlocal/banking-statements")}
      mountParcel={mountRootParcel}
      wrapStyle={{
        height: "100%",
        overflow: "hidden",
      }}
      type={["Pix"]}
    />
  );
}
