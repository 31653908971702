import { ComponentProps, useCallback, useEffect, useReducer } from "react";
import { useGetKeys } from "@/api";
import NewKey from "@/components/NewKey";
import { Balance } from "@/pages/contestation/components";
import {
  AccessGroupName,
  getPixRoutesData,
  getTokenData,
  getTransfersRoutesData,
  hasPermission,
  Hooks,
  MyKeysResponse,
  setHeader,
  Validator,
} from "@/utils";
import { Drawer, IconButton, Modal } from "@hyperlocal/vital";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { toast } from "@hyperlocal/vital2";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useUrlDisclosure } from "../../hooks";
import { useDisclosure } from "../../hooks/useDisclosure";
import Dashboard from "../Dashboard";
import { Layout } from "../Layout";
import { GraphicWidget } from "../Widgets";
import { MyKeysDrawer, PixDrawer, PixModal } from "./DrawersAndModals";
import * as Styles from "./styles";
import { TransactionsList } from "./TransactionsList";

type CardTitle =
  | "Extrato"
  | "Transferir"
  | "Pagar QR Code"
  | "Pix Copia e Cola"
  | "Cobrar"
  | "Minhas Chaves"
  | "Contestações";

type ICardProps = {
  to: string;
  title: CardTitle;
  allowedRoles?: AccessGroupName[];
  isPixKeyRequired: boolean;
} & (
  | { icon: IconProps["name"]; svg?: never }
  | { svg: (props: ComponentProps<"svg">) => JSX.Element; icon?: never }
);

const defaultCards: ICardProps[] = [
  {
    to: getPixRoutesData().statements + "?category=Pix",
    title: "Extrato",
    icon: "GeralReceiptReceipt",
    isPixKeyRequired: false,
  },
  {
    to: `${getPixRoutesData().transfer}?type=pix`,
    title: "Transferir",
    icon: "GeralArrowArrowTransfer",
    allowedRoles: ["Proprietário", "Administrador", "Moderador", "Financeiro"],
    isPixKeyRequired: false,
  },
  {
    to: getPixRoutesData().cashOutQR,
    title: "Pagar QR Code",
    icon: "GeralQrCode",
    isPixKeyRequired: false,
  },
  {
    to: getPixRoutesData().copyPaste,
    title: "Pix Copia e Cola",
    icon: "GeralGeralCopy",
    isPixKeyRequired: false,
  },
  {
    to: getPixRoutesData().pixCharge,
    title: "Cobrar",
    icon: "GeralMoneyPayDemand",
    allowedRoles: ["Proprietário", "Administrador", "Moderador", "Financeiro"],
    isPixKeyRequired: true,
  },
  // {
  //   to: getPixRoutesData().pixContestation,
  //   title: "Contestações",
  //   svg: Svg.FileSearch,
  // },
  {
    to: getPixRoutesData().pixMyKeys,
    title: "Minhas Chaves",
    icon: "GeralKey",
    allowedRoles: ["Proprietário", "Administrador", "Moderador", "Financeiro"],
    isPixKeyRequired: false,
  },
];

function filterCardsByTitle(
  titles: ICardProps["title"][],
  cardArr: ICardProps[],
): ICardProps[] {
  return cardArr.filter((card) => titles.includes(card.title));
}

const transactionSectionCards = filterCardsByTitle(
  ["Transferir", "Pagar QR Code", "Pix Copia e Cola", "Cobrar"],
  defaultCards,
).sort((a, b) => Number(a.title > b.title));

const dataSectionCards = filterCardsByTitle(
  ["Minhas Chaves", "Extrato"],
  defaultCards,
).sort((a, b) => {
  if (a.title < b.title) return 1;
  if (a.title > b.title) return -1;
  return 0;
});

const desktopCards = filterCardsByTitle(
  [
    "Extrato",
    "Transferir",
    "Pix Copia e Cola",
    "Cobrar",
    "Contestações",
    "Minhas Chaves",
  ],
  defaultCards,
);

function getHasClaim(keys: MyKeysResponse[]) {
  return keys?.some((key) => key.keyStatus === "OPEN");
}

export default function PixDashboard() {
  const isDesktop = Hooks.useMediaQuery("desktop");
  const isMobile = Hooks.useMediaQuery("mobile");
  const isIos = Hooks.useMobileDetect().isIos();
  const { data: keys = [], isLoading, isFetched } = useGetKeys();

  const {
    drawerContent: drawer,
    handleDrawerContent: setDrawer,
    modalContent: modal,
    handleModalContent: setModal,
  } = useDisclosure();

  const newKeyDrawer = useUrlDisclosure("newKey");
  const myKeysDrawer = useUrlDisclosure("myKeys");

  const { checkPixKey, checkPixCode } = Validator;

  const [searchParam, setSearchParam] = useSearchParams();

  const [clipboardState, updateClipboardState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { clipboard: "", error: "" },
  );

  const navigate = useNavigate();

  const handleCopiedPix = useCallback(async () => {
    if (!keys?.length) {
      newKeyDrawer.open();
      return setModal({ isOpen: false });
    }

    if (checkPixCode(clipboardState.clipboard)) {
      return navigate(getPixRoutesData().copyPaste);
    }

    try {
      return navigate(`${getTransfersRoutesData().transfers}?type=pix`);
    } catch ({ response: { data: responseData } }) {
      const errorMessage =
        responseData?.errors[0]?.friendlyMessage || "Chave inválida";
      updateClipboardState({ error: errorMessage }); // Implementar snackbar
    }

    return null;
  }, [
    keys?.length,
    checkPixCode,
    clipboardState.clipboard,
    newKeyDrawer,
    setModal,
    navigate,
  ]);

  const openRegisterNeyKeyModal = () => {
    setModal({
      variant: "button",
      textContent:
        "Cadastre uma chave Pix para começar a usar, facilite os seus recebimentos e transferências",
      title: "Cadastrar chave Pix",
      onClose: modal?.onClose,
      footerProps: {
        variant: "button",
        buttonsProps: [
          {
            children: "Cadastrar Chave Pix",
            variant: "primary",
            onClick: () => {
              setModal({ isOpen: false });
              newKeyDrawer.open();
            },
          },
        ],
      },
      isOpen: true,
    });
  };

  const openPixDrawer = useCallback(() => {
    if (isMobile) {
      setDrawer({
        title: "Efetuar Pix?",
        open: true,
        content: (
          <PixDrawer
            clipboardState={clipboardState}
            getPixCopiedText={getPixCopiedText}
            handleCopiedPix={handleCopiedPix}
          />
        ),
      });
    } else {
      setModal({
        variant: "custom",
        title: "Efetuar Pix?",
        textContent: "",
        isOpen: true,
        children: (
          <PixModal
            clipboardState={clipboardState}
            getPixCopiedText={getPixCopiedText}
            handleCopiedPix={handleCopiedPix}
          />
        ),
      });
    }
  }, [isMobile, setDrawer, clipboardState, handleCopiedPix, setModal]);

  const getPixCopiedText = (pixType: string) => {
    if (pixType === "Chave Aleatória") {
      return `Existe uma ${pixType} copiada, deseja fazer Pix?`;
    }
    return `Existe um ${pixType} copiado, deseja fazer Pix?`;
  };

  const closeDrawer = () => {
    searchParam.delete("newKey");
    searchParam.delete("myKeys");
    setSearchParam(searchParam);
    window.localStorage.removeItem("PixCreateStore");
    window.localStorage.removeItem("TokenStore");
    // setHeader({
    //   show: false,
    // });

    setDrawer({
      content: null,
      open: false,
      title: "",
    });
  };

  const handleReadClipboard = async () => {
    try {
      if (!isIos) {
        const text = await navigator.clipboard.readText();

        if (checkPixKey(text)) {
          updateClipboardState({ clipboard: text });
        }
      }
    } catch (error) {
      toast({
        title:
          "Não foi possível ler o conteúdo da área de transferência. Verifique as permissões.",
        variant: "inform",
      });
    }
  };

  const backFromTokenHandler = useCallback(() => {
    const token = getTokenData();

    const routesMap = {
      [`${getPixRoutesData().pix}?newKey=open`]: () => newKeyDrawer.open(),
      [`${getPixRoutesData().pix}?myKeys=open`]: () => myKeysDrawer.open(),
    };
    return routesMap[token?.request.path]();
  }, [newKeyDrawer, myKeysDrawer]);

  useEffect(() => {
    // Necessário o timeout para adicionar o header no mobile depois que o header define se deve ou não aparecer
    // ! SE REMOVER VAI SUMIR O HEADER AO ENTRAR NA TELA
    const timer = setTimeout(() => {
      setHeader({
        title: "Pix",
        leftIcon: {
          name: "ArrowArrowNoLineLeft",
          onClick: () => navigate("/"),
        },
      });
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, isDesktop]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleReadClipboard();
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const hasClipboard = clipboardState.clipboard;
    const isPixKey = Boolean(checkPixKey(clipboardState.clipboard));
    const hasClaim = getHasClaim(keys);

    if (hasClipboard && isPixKey && hasClaim && isFetched) {
      openPixDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPixKey, clipboardState, keys, isFetched]);

  useEffect(() => {
    window.addEventListener("@hyperlocal-token-validation/close-drawer", () => {
      backFromTokenHandler();
    });

    return () => {
      window.removeEventListener(
        "@hyperlocal-token-validation/close-drawer",
        undefined,
        false,
      );
    };
  }, [backFromTokenHandler]);

  function handleOpenMyKeysDrawer(title: CardTitle) {
    title === "Minhas Chaves" ? myKeysDrawer.open() : openRegisterNeyKeyModal();
  }

  return (
    <div className="mx-auto max-w-[1248px] desktop:my-inset-lg desktop:px-16 [&>div>div]:p-0">
      <Layout
        title="Pix"
        boxType="outer"
        breadcrumb={["Pix"]}
        onBreadcrumbClick={() => navigate(getPixRoutesData().dashboard)}
        isDesktop={isDesktop}
      >
        <Styles.Container>
          {!isMobile && (
            <article className="flex gap-inset-x2s mobile:!hidden">
              {desktopCards.map((card) => {
                const canSeeCard = card.allowedRoles
                  ? hasPermission(card.allowedRoles)
                  : true;
                if (!canSeeCard) {
                  return null;
                }

                const isDisabled = isLoading;
                const hasKeys = keys.length > 0;
                const isAnchorCard = [
                  "Extrato",
                  "Contestações",
                  "Cobrar",
                  "Pix Copia e Cola",
                  "Transferir",
                ].includes(card.title);

                const SvgIcon = card.svg;

                const IconElement = SvgIcon ? (
                  <SvgIcon height="32" />
                ) : (
                  <Icon name={card.icon} height="32" fill="currentColor" />
                );

                const isPixRequired = card.isPixKeyRequired ? !!hasKeys : true;

                const shouldUseAnchor = isAnchorCard && isPixRequired;

                if (shouldUseAnchor) {
                  return (
                    <Styles.Anchor
                      key={card.title}
                      to={!isDisabled ? card.to : undefined}
                      aria-disabled={isDisabled}
                      role="link"
                      shadow="lower"
                    >
                      {IconElement}
                      <Styles.CardText>{card.title}</Styles.CardText>
                    </Styles.Anchor>
                  );
                }

                return (
                  <Styles.Button
                    key={card.title}
                    onClick={() => handleOpenMyKeysDrawer(card.title)}
                    disabled={isDisabled}
                    shadow="lower"
                  >
                    {IconElement}
                    <Styles.CardText>{card.title}</Styles.CardText>
                  </Styles.Button>
                );
              })}
            </article>
          )}

          <Styles.Content>
            <Styles.LeftWrapper>
              <Styles.Balance>
                <Balance />
              </Styles.Balance>

              <Styles.Pix>
                <Dashboard handleOpenNewKeysDrawer={newKeyDrawer.open} />
              </Styles.Pix>

              <Styles.Category>
                <h4>Transações:</h4>

                <Styles.CardsContainer direction="row">
                  {transactionSectionCards.map((card) => (
                    <IconButton
                      key={card.title}
                      variant="secondary"
                      icon={card.icon}
                      label={card.title}
                      onClick={() => navigate(card.to)}
                    />
                  ))}
                </Styles.CardsContainer>
              </Styles.Category>

              <Styles.Category>
                <h4>Dados:</h4>

                <Styles.CardsContainer>
                  {dataSectionCards.map((card) => (
                    <Styles.MobileCard
                      key={card.title}
                      role="button"
                      onClick={() => navigate(card.to)}
                    >
                      <div>
                        <Icon name={card.icon} />
                        {card.title}
                      </div>
                      <Icon name="ArrowArrowNoLineRight" />
                    </Styles.MobileCard>
                  ))}
                </Styles.CardsContainer>
              </Styles.Category>

              <GraphicWidget />
            </Styles.LeftWrapper>

            <Styles.RightWrapper className="p-0">
              <TransactionsList />
            </Styles.RightWrapper>
          </Styles.Content>
        </Styles.Container>
      </Layout>

      <Styles.ModalWrapper>
        <Modal
          isOpen={modal?.isOpen}
          onClose={modal?.onClose}
          variant={modal?.variant}
          title={modal?.title}
          textContent={modal?.textContent}
          footerProps={modal?.footerProps}
        >
          {modal?.children}
        </Modal>
      </Styles.ModalWrapper>

      <Styles.DrawerWrapper>
        <Drawer
          open={drawer?.open ?? false}
          title={drawer?.title}
          onDismiss={closeDrawer}
          position={isMobile ? "bottom" : "right"}
          className="drawer"
        >
          {drawer?.content}
        </Drawer>

        <Drawer
          open={newKeyDrawer.isOpen}
          title="Nova Chave"
          onDismiss={newKeyDrawer.close}
          position={isMobile ? "bottom" : "right"}
          className="drawer"
        >
          <NewKey key={String(newKeyDrawer.isOpen)} />
        </Drawer>

        <Drawer
          open={myKeysDrawer.isOpen}
          title="Minhas Chaves Pix"
          onDismiss={myKeysDrawer.close}
          position={isMobile ? "bottom" : "right"}
          className="drawer"
        >
          <MyKeysDrawer
            key={String(myKeysDrawer.isOpen)}
            handleModal={setModal}
          />
        </Drawer>
      </Styles.DrawerWrapper>

      <Outlet />
    </div>
  );
}
