export const formatKeyTypes = (key: string, type: string) => {
  const formatMap: Record<string, string> = {
    CPF: key?.replace(/[^0-9]/g, ""),
    CNPJ: key?.replace(/[^0-9]/g, ""),
    EVP: "",
    PHONE: key?.replace(/\D/g, ""),
  };

  return formatMap[type] || key;
};
