import { Avatar as VitalAvatar, Card as VitalCard } from "@hyperlocal/vital";
import { Link } from "react-router-dom";
import styled, {
  createGlobalStyle,
  css,
  StyledComponent,
} from "styled-components";
import { Box } from "../Box";

export const GlobalStyle = createGlobalStyle`
  @media print {
    *:not(.printable, .printable *, :has(.printable)), .noPrint {
      display: none !important;
    }

    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
`;

export const DrawerWrapper = styled.div`
  & > div > div:last-of-type {
    max-width: 480px;

    & > div:last-of-type {
      height: calc(100% - 32px);
    }
  }
`;

export const ModalWrapper = styled.div`
  & > div {
    z-index: 100000;
    & > main {
      & > div:nth-child(3) {
        word-break: normal;
      }
    }
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.lg};

  width: 100%;
  height: 100%;

  padding: ${({ theme }) =>
    `${theme.spacings.inset.xl} ${theme.spacings.inset.md}`};

  font-family: ${({ theme }) => theme.fonts.base};

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      padding: unset;
    `)}
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.md};

  & > h4 {
    font-family: ${({ theme }) => theme.fonts.base};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    line-height: ${({ theme }) => theme.lineHeights.default};
    color: ${({ theme }) => theme.palette.neutral.darkest};
  }

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      display: none;
      gap: ${theme.spacings.stack.xs};
    `)}
`;

type ICardsContainerProps = {
  direction?: "row" | "column";
};

export const CardsContainer = styled.article<ICardsContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "column"};
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.inset.md};

  padding: 0px ${({ theme }) => theme.spacings.inset.md};

  width: 100%;
  height: 100%;

  text-align: center;

  & > div > div {
    font-size: ${({ theme }) => theme.fontSizes.x4s};
  }
`;

// export const DesktopCardsContainer = styled(CardsContainer)`
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   gap: ${({ theme }) => theme.spacings.inset.x2s};

//   width: 652px;
//   height: 112px;

//   ${({ theme }) =>
//     theme.breakpoints.mobile(`
//       display: none;
//     `)}

//   ${({ theme }) =>
//     theme.breakpoints.tablet(`
//       display: none;
//     `)}
// `;

export const Anchor = styled(
  Box.withComponent(Link as StyledComponent<any, any, {}, never>),
)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inline.xs};

  padding: ${({ theme }) =>
    `${theme.spacings.inset.md} ${theme.spacings.inset.x2s}`};

  height: 112px;
  width: 124px;

  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;

  background: ${({ theme }) => theme.palette.neutral.white};

  &[aria-disabled="true"] {
    color: ${({ theme }) => theme.palette.neutral.light};
    background: ${({ theme }) => theme.palette.neutral.lighter};

    cursor: auto;
    pointer-events: none;
  }

  > svg {
    flex-shrink: 0;
  }
`;

export const Button = styled(Box.withComponent("button"))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inline.xs};

  padding: ${({ theme }) =>
    `${theme.spacings.inset.md} ${theme.spacings.inset.x2s}`};

  height: 112px;
  width: 124px;

  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;

  background: ${({ theme }) => theme.palette.neutral.white};

  cursor: pointer;

  :disabled {
    color: ${({ theme }) => theme.palette.neutral.light};
    background: ${({ theme }) => theme.palette.neutral.lighter};

    cursor: auto;
    pointer-events: none;
  }

  > svg {
    flex-shrink: 0;
  }
`;

export const CardText = styled.span`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.x2s};
  line-height: ${({ theme }) => theme.lineHeights.md};
  text-align: center;
`;

export const MobileCard = styled(
  VitalCard as StyledComponent<any, any, {}, never>,
)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 100%;
  width: 100% !important;
  max-width: 100%;
  max-height: 48px;

  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes.xs};

  & > div {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacings.inset.x2s};
  }

  & svg {
    height: 32px;
    fill: ${({ theme }) => theme.palette.neutral.darkest};
  }

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      & svg {
        height: ${theme.spacings.inset.lg};
        fill: ${theme.palette.neutral.darkest};
      }
    `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.lg};

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      display: grid;
      grid-template-columns: 1fr 1fr;
      
      position: relative;
    `)}
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.lg};

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      width: 100%;
      height: 100%;
    `)}

  ${({ theme }) =>
    theme.breakpoints.tablet(`
      width: 100%;
      height: 100%;
    `)}
`;

export const Pix = styled.div`
  display: none;
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacings.squished.lg};

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      display: flex;
    `)}
`;

export const Balance = styled(Box.withComponent("article"))`
  ${({ theme }) =>
    theme.breakpoints.mobile(`
      display: none;

      & > div {
        display: none;
      }
    `)}

  ${({ theme }) =>
    theme.breakpoints.tablet(`
      display: none;

      & > div {
        display: none;
      }
    `)}
`;

export const IconButton = styled.button`
  all: unset;
  cursor: pointer;

  & > svg {
    height: 24px;
    min-height: 24px;
    fill: ${({ theme }) => theme.palette.neutral.darkest};

    &:hover {
      fill: ${({ theme }) => theme.palette.neutral.dark};
    }
  }
`;

export const RightWrapper = styled(Box.withComponent("article"))`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.inset.lg};

  padding: ${({ theme }) => theme.spacings.inset.xl};

  position: absolute;
  inset: 0;
  grid-column: 2;

  max-width: 664px;

  ${({ theme }) =>
    theme.breakpoints.mobile(`
    display: none;
  `)}

  ${({ theme }) =>
    theme.breakpoints.tablet(`
    display: none;
  `)}
`;

type IVitalAvatarProps = {
  type: "income" | "outcome";
};

export const Avatar = styled(VitalAvatar)<IVitalAvatarProps>`
  background: ${({ theme, type }) => {
    if (type === "income") return theme.palette.secondary.lighter;
    if (type === "outcome") return theme.palette.neutral.lighter;
    return theme.palette.neutral.lighter;
  }};

  & > svg > path {
    fill: ${({ theme, type }) => {
      if (type === "income") return theme.palette.secondary.darker;
      if (type === "outcome") return theme.palette.neutral.darkest;
      return theme.palette.neutral.darkest;
    }};
  }
`;

export const Card = styled(VitalCard as StyledComponent<any, any, {}, never>)`
  width: 100%;
  height: 73px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.inset.md};

  padding: ${({ theme }) => theme.spacings.squished.md};

  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PixModal = styled.div`
  ${({ theme }) => css`
    & > div {
      width: auto !important;
      display: flex;
      align-items: center;
      margin: ${theme.spacings.inline.xs} 0 ${theme.spacings.inline.x2s};

      & > .pixData {
        word-break: break-word;
        width: 100%;
        margin-left: 10px;
      }

      & > svg {
        width: 19px;
        height: 17.5px;
      }
    }

    & > button {
      width: 100% !important;
      justify-content: center !important;
    }
  `}
`;

export const PixDrawer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;

    & > nav {
      margin-bottom: 32px;
    }

    & > nav > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: ${({ theme }) => theme.spacings.inset.x2s};

        & > div {
          & > span {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            &:first-of-type {
              display: block;
            }

            &:last-of-type {
              word-break: break-word;
            }
          }
        }

        & > p {
          display: flex;
          flex-direction: column;
        }

        & > p :nth-child(1) {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
      }

      & > svg {
        width: 20px;
        height: auto;
        fill: ${theme.palette.neutral.darker};
      }
    }

    & > button {
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      &.myKeysButton {
        margin-top: ${theme.spacings.stack.x2l};
      }
    }
  `}
`;

export const ModalNewKey = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > p {
    margin-top: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const QRModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.8);

    z-index: 999;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;

      width: 100%;
      height: 100%;

      & > svg {
        width: 24px;
        height: 24px;

        position: absolute;
        right: 16px;
        top: 16px;

        fill: ${theme.palette.neutral.white};
      }

      & > img {
        width: 240px;
        height: 240px;
      }
    }
  `}
`;

export const ErrorFeedback = styled.p`
  color: ${({ theme }) => theme.palette.status.error.errorDefault};
`;
