import {
  API,
  formatCurrency,
  useAccountStore,
  useNewStatementPermission,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import { format, parse } from "date-fns";
import Chart from "react-apexcharts";
import { useTheme } from "styled-components";
import * as S from "./GraphicWidget.styles";

type Reducing = {
  categories: string[];
  credit: number[];
  debit: number[];
  totalCredit: number;
  totalDebit: number;
};

const MAX_LENGTH = 7;

export interface GetChartDataResponse {
  Balance: {
    Credit: number;
    Debit: number;
  };
  Week: {
    Start: string;
    End: string;
  };
  Items: {
    Date: string;
    Credit: number;
    Debit: number;
  }[];
}

export interface GetChartDataResponseV2 {
  balance: {
    credit: number;
    debit: number;
  };
  week: {
    start: string;
    end: string;
  };
  items: {
    date: string;
    credit: number;
    debit: number;
  }[];
}

type WeekResponse = GetChartDataResponse | GetChartDataResponseV2;

export interface ChartData {
  categories: string[];
  totalCredit: number;
  totalDebit: number;
  chatData: [
    { name: "Entradas"; data: number[] },
    { name: "Saídas"; data: number[] },
  ];
}

function normalizeItems(response: WeekResponse) {
  if ("Items" in response) {
    return response.Items.map((item) => ({
      date: item.Date,
      credit: item.Credit,
      debit: item.Debit,
    }));
  } else {
    return response.items;
  }
}

const transformWeeklyResponse = ({
  response,
  isNewStatement,
}: {
  isNewStatement: boolean;
  response: WeekResponse;
}): ChartData => {
  const normalizedItems = normalizeItems(response);

  const result = normalizedItems.reduce<Reducing>(
    (prev, cur, index) => {
      if (index <= MAX_LENGTH) {
        prev.totalCredit += cur.credit;
        prev.totalDebit += cur.debit;

        const parsedDate = parse(cur.date, "yyyy-MM-dd", new Date());
        prev.categories.push(format(parsedDate, "dd/MM"));
        prev.credit.push(cur.credit || -0.01);
        prev.debit.push(Math.abs(cur.debit) || -0.01);
      }

      return prev;
    },
    {
      categories: [],
      credit: [],
      debit: [],
      totalCredit: 0,
      totalDebit: 0,
    },
  );

  return {
    categories: isNewStatement
      ? result.categories
      : result.categories.reverse(),
    totalCredit: result.totalCredit,
    totalDebit: result.totalDebit,
    chatData: [
      {
        name: "Entradas",
        data: isNewStatement ? result.credit : result.credit.reverse(),
      },
      {
        name: "Saídas",
        data: isNewStatement ? result.debit : result.debit.reverse(),
      },
    ],
  };
};

export async function getExtractWeekly({
  accountId,
  useNewStatement,
}: {
  accountId: string;
  useNewStatement: boolean;
}) {
  try {
    const apiUrl = useNewStatement
      ? "/v2/Extract/Week"
      : `/Extract/Week?accountId=${accountId}`;
    const response = await API.statements.get<GetChartDataResponse>(apiUrl);
    return response.data;
  } catch (error) {
    // toast.error("Não foi possível buscar as contestações.");
    throw new Error(error);
  }
}

export function usePixGraphic() {
  const { currentAccountId: accountId } = useAccountStore();
  const useNewStatement = useNewStatementPermission();

  return useQuery({
    queryKey: ["weeklyReport", accountId],
    queryFn: () => getExtractWeekly({ accountId, useNewStatement }),
    enabled: !!accountId,
    initialData: {
      Balance: {
        Credit: 0,
        Debit: 0,
      },
      Items: [],
      Week: { End: "", Start: "" },
    },
    select: (pixResponse) =>
      transformWeeklyResponse({
        isNewStatement: useNewStatement,
        response: pixResponse,
      }),
  });
}

export function GraphicWidget() {
  const theme = useTheme();
  const { data, isLoading, isError, isFetching } = usePixGraphic();

  const loadingData = isLoading || isFetching;

  const filteredData = data?.chatData || [];

  const getChartEmptyFeedback = () => {
    if (loadingData && !data.categories.length) return "Carregando...";

    if (isError) return "Houve um problema ao buscar os dados";

    if (data && !data.categories.length)
      return "Não houve movimentações no período";
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 305,
      toolbar: {
        show: false,
      },
      offsetX: -8,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["transparent"],
    },
    xaxis: {
      categories: data?.categories || [],
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          const value = val || 0;

          return new Intl.NumberFormat("pt-BR", {
            maximumFractionDigits: 2,
          }).format(value);
        },
      },
      forceNiceScale: true,
      min: 0,
    },
    fill: {
      colors: [
        theme.palette.primary.main,
        theme.palette.status.error.errorDark,
      ],
    },
    tooltip: {
      y: {
        formatter: (val) => `${val > 0 ? formatCurrency(val) : "0,00"}`,
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: getChartEmptyFeedback(),
      align: "center",
      verticalAlign: "middle",
      offsetX: 15,
      offsetY: -15,
    },
  };

  return (
    <S.Wrapper>
      <Chart
        options={options}
        series={filteredData}
        type="bar"
        width={500}
        height={320}
      />
      <S.Container>
        <S.ValueWrapper>
          <S.ValueTitle>Entradas</S.ValueTitle>
          <S.Value>{formatCurrency(data?.totalCredit || 0)}</S.Value>
        </S.ValueWrapper>
        <S.ValueWrapper>
          <S.ValueTitle>Saídas</S.ValueTitle>
          <S.Value isDebit>{formatCurrency(data?.totalDebit || 0)}</S.Value>
        </S.ValueWrapper>
      </S.Container>
    </S.Wrapper>
  );
}
