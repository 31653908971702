import { Breadcrumb as vitalBreadcrumb } from "@hyperlocal/vital";
import styled, { StyledComponent } from "styled-components";

export const Container = styled.div`
  display: flex;

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      width: 100vw;
      min-height: auto;
      padding: 0;
      background: ${theme.palette.neutral.white};
    `)}
`;

export const Breadcrumb = styled(
  vitalBreadcrumb as StyledComponent<any, any, {}, never>,
)`
  display: none;
  ${({ theme }) =>
    theme.breakpoints.desktop(`
      display: flex;
    `)}
`;

type IBoxProps = {
  type?: "inner" | "outer";
};

export const Box = styled.div<IBoxProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, type }) =>
    type === "inner" ? theme.spacings.inline.xs : theme.spacings.inline.sm};

  ${({ theme, type }) =>
    theme.breakpoints.mobile(`
      gap: 0;
      display: ${type === "inner" ? "none" : "flex"};
    `)}

  ${({ theme, type }) =>
    theme.breakpoints.tablet(`
      gap: 0;
      display: ${type === "inner" ? "none" : "flex"};
    `)}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding: 0;

  background-color: ${({ theme }) => theme.palette.neutral.white};

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      padding: ${theme.spacings.stack.x3s} ${theme.spacings.stack.xl};
      margin: 0 auto;

      background: ${theme.palette.neutral.lighter};
      max-width: 1440px;
    `)}
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.base};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: ${({ theme }) => theme.lineHeights.default};
  color: ${({ theme }) => theme.palette.neutral.darkest};
  display: none;
  ${({ theme }) =>
    theme.breakpoints.desktop(`
    display: flex;
    `)}

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.nano};
`;
