import { useCallback } from "react";

export const useSessionStorage = () => {
  const saveValue = useCallback((key: string, value: string) => {
    sessionStorage.setItem(key, value);
  }, []);

  const getValue = useCallback((key: string): string | null => {
    return sessionStorage.getItem(key);
  }, []);

  const updateValues = useCallback(
    (newValues: Record<string, string>) => {
      Object.entries(newValues).forEach(([key, value]) => {
        saveValue(key, value);
      });
    },
    [saveValue],
  );

  const removeValue = useCallback((key: string) => {
    sessionStorage.removeItem(key);
  }, []);

  const clearValues = useCallback(() => {
    sessionStorage.clear();
  }, []);

  return {
    saveValue,
    getValue,
    updateValues,
    removeValue,
    clearValues,
  };
};
