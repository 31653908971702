import { API } from "@/utils/utility";

export type FindKeyResponse = {
  keyType: string;
  idEndToEnd: string;
  ispb: string;
  bankName: string;
  bankAccountNumber: string;
  bankBranchNumber: string;
  bankAccountType: string;
  personType: string;
  document: string;
  documentMask: string;
  name: string;
  key: string;
};

export type FindPixKeyRequest = {
  key: string;
  accountId: string;
};

export type CreatePixKeyRequest = {
  type: string;
  token: string;
  key: string | undefined;
  deviceId: string;
};

type ClaimRequestBase = {
  token: string;
  deviceId: string;
  claimId: string;
  key: string;
  keyType: string;
};

export type AproveClaimRequest = ClaimRequestBase;

export type DeclineClaimRequest = ClaimRequestBase;

export type DeletePixKeyRequest = {
  key: string;
  deviceId: string;
};

type ClaimResponse = { message: string };

export type SendVerifyingTokenRequest = {
  key: string;
  tokenTypeId: string;
  keyType: "email" | "cellPhoneNumber";
};

async function findPixKey({ key, accountId }: FindPixKeyRequest) {
  const response = await API.pix.get<FindKeyResponse>("/api/v1/pix/Key/find", {
    params: {
      Key: key,
      AccountId: accountId,
    },
  });

  return response.data;
}

async function createPixKey(input: CreatePixKeyRequest) {
  const response = await API.pix.post("/api/v1/pix/Key", input);
  return response.data;
}

async function approveClaim({
  claimId,
  deviceId,
  token,
  key,
  keyType,
}: AproveClaimRequest) {
  const response = await API.pix.post<ClaimResponse>(
    "/api/v1/pix/Claims/approve",
    {
      claimUUID: claimId,
      deviceId,
      token,
      key,
      type: keyType,
    },
  );
  return response.data;
}

async function declineClaim({
  claimId,
  deviceId,
  token,
  key,
  keyType,
}: DeclineClaimRequest) {
  const response = await API.pix.post<ClaimResponse>(
    "/api/v1/pix/Claims/refused",
    {
      claimUUID: claimId,
      deviceId,
      token,
      key,
      type: keyType,
    },
  );
  return response.data;
}

async function deletePixKey(data: DeletePixKeyRequest) {
  await API.pix.delete("/api/v1/pix/Key", {
    data,
  });
}

async function generateStaticQrCode(key: string) {
  const response = await API.pix.post("/api/v1/pix/QrCode/static", { key });
  return response.data;
}

async function sendVerifyingToken({
  key,
  tokenTypeId,
  keyType,
}: SendVerifyingTokenRequest) {
  await API.pix.post("/api/v1/pix/token/create", {
    tokenTypeId,
    [keyType]: key,
  });
}

export const pixService = {
  findPixKey,
  createPixKey,
  approveClaim,
  declineClaim,
  deletePixKey,
  generateStaticQrCode,
  sendVerifyingToken,
};
