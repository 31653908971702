import { useState } from "react";
import { ClaimType } from "@/utils";
import { Button, Token } from "@hyperlocal/vital2";
import { Timer } from "./components/Timer";
import type { KeyType } from "./types";

interface TokenSheetProps {
  isExternalCode: boolean;
  keyType: KeyType;
  newPixKey: string;
  goToNextStep: () => void;
  onSendToken: () => void;
  openClaimDrawer: (type: ClaimType) => void;
  onSubmit: (token: string) => void;
}

export function TokenSheet({
  isExternalCode,
  keyType,
  newPixKey,
  onSendToken,
  onSubmit,
}: TokenSheetProps) {
  const [totpCode, setTotpCode] = useState("");

  const isDisabled = totpCode.length !== 6;

  const getContentText = () => {
    if (["E-mail", "Celular"].includes(keyType))
      return `Digite o código que foi enviado via ${keyType === "E-mail" ? keyType : "SMS"}:`;

    return (
      <>
        Digite o código do Token que foi <br /> enviado via{" "}
      </>
    );
  };

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="mt-4 text-xs/md font-bold text-neutral-darkest mobile:text-xs">
        {getContentText()}

        {!isExternalCode && (
          <span className="text-primary-main">aplicativo autenticador</span>
        )}
      </h2>

      {isExternalCode && (
        <h5 className="font-inter text-[20px] font-bold leading-[30px] text-primary-main">
          {newPixKey}
        </h5>
      )}

      <div className="mt-8">
        <Token.Label className="mobile:text-x2s/default">
          Código de 6 caracteres
        </Token.Label>
        <Token.Root onValueChange={setTotpCode}>
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
          <Token.Input />
        </Token.Root>
      </div>

      {["E-mail", "Celular"].includes(keyType) && (
        <Timer resendToken={() => onSendToken()} />
      )}

      <Button.Root
        variant="primary"
        className="mt-auto w-full justify-center"
        onClick={() => onSubmit(totpCode)}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
