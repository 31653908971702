/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Divider } from "@hyperlocal/vital";
import * as Types from "../../services/types";
import * as Styles from "./styles";

const ContentQR: React.FC<{
  defaultKey: Types.IGetKeys;
  closeModal: () => void;
}> = ({ defaultKey, closeModal }) => {
  const sharePrint = () => {
    window.print();
  };

  return (
    <Styles.QRModalImage>
      {/* @ts-ignore */}
      <Styles.GlobalStyle />
      {/*  <Logo /> */}
      <section className="printable">
        {/* <Icon name="Avecpay" /> */}

        <img
          src={`data:image/png;base64,${defaultKey?.keyQrCode}`}
          alt="qrcode"
        />
        <Divider />
        <h2>Informações da Chave Pix</h2>
        <p>
          <strong>Favorecido</strong>
          <span>{defaultKey?.account.name}</span>
        </p>
        <p>
          <strong>Chave Pix</strong>
          <span>{defaultKey?.key}</span>
        </p>
        <p>
          <strong>Instituição</strong>
          <span>HyperLocal</span>
        </p>
      </section>
      <div className="noPrint">
        <Button onClick={sharePrint}>Imprimir</Button>
        <Button variant="secondary" onClick={closeModal}>
          Fechar
        </Button>
      </div>
    </Styles.QRModalImage>
  );
};

export default ContentQR;
