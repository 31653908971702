/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getPixRoutesData,
  getVertical,
  globalQueryClient,
  Hooks,
  themeProvider,
} from "@/utils";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./App.styles";
import { AppProvider } from "./context";
import { Routes } from "./routes";

function App() {
  const routes = getPixRoutesData();
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      {/* @ts-ignore */}
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <AppProvider>
            {/* @ts-ignore */}
            <GlobalStyles />

            <QueryClientProvider
              client={globalQueryClient as unknown as QueryClient}
            >
              <ReactQueryDevtools />
              <Routes defaultRoutes={routes} />

              <Toaster position={isMobile ? "bottom-center" : "top-right"} />
            </QueryClientProvider>
          </AppProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
