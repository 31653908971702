import { useCallback, useEffect, useState } from "react";
import { useGetKeys } from "@/api";
import { TokenSheet } from "@/components/NewKey/token";
import { usePixKeysContext } from "@/context/Pix";
import { useSessionStorage } from "@/hooks";
import type { CreatePixKeyRequest } from "@/services";
import { pixService } from "@/services";
import {
  claimTypes,
  createPixClaimId,
  tokenRequestClaimGuid,
  tokenRequestGuid,
} from "@/utils/constants";
import { formatKeyTypes, formatPhoneNumber, unmaskDocument } from "@/utils/pix";
import type { ClaimType } from "@/utils/types";
import {
  API,
  getCreateKey,
  getPixKey,
  getPixRoutesData,
  globalQueryClient,
  Hooks,
  queries,
  setCreateKey,
  setHeader,
  setTokenData,
  useAccountStore,
  useDeviceFingerprint,
  useUserStore,
  Validator,
} from "@/utils/utility";
import { Button, Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { toast } from "@hyperlocal/vital2";
import { useNavigate } from "react-router-dom";
import { usePrint } from "../../context/Print";
import PrintableQRCode from "../PrintableQRCode";
import SharedDrawer from "../ShareKey";
import ContentQR from "../ShareModalQR";
import { InsertKey } from "./InsertKey";
import { SelectKey } from "./SelectKey";
import * as S from "./styles";
import { SuccessScreen } from "./SuccessScreen";
import type { KeyType } from "./types";

const PAGES = {
  SELECT_KEY: "SELECT_KEY",
  INSERT_KEY: "INSERT_KEY",
  SUCCESS_SCREEN: "SUCCESS_SCREEN",
} as const;

type Page = (typeof PAGES)[keyof typeof PAGES];

const formattedKeyTypes: Partial<Record<KeyType, string>> = {
  "E-mail": "email",
  Celular: "PhoneNumber",
  CNPJ: "nationalRegistration",
  CPF: "nationalRegistration",
};

async function checkPixKeyExists(pixKey: string, accountId: string) {
  try {
    return await pixService.findPixKey({
      key: pixKey.toLowerCase(),
      accountId,
    });
  } catch (error) {
    const friendlyMessage = error?.response?.data?.errors?.[0]?.friendlyMessage;
    const errorMessage = error?.response?.data?.errors?.[0]?.message;

    if (
      friendlyMessage?.includes("Chave informada não existe") ||
      errorMessage?.includes("Chave informada não existe")
    ) {
      return null;
    }

    throw error;
  }
}

function determineClaimType(accountDocument: string, keyDocument: string) {
  const accountDocClean = unmaskDocument(accountDocument || "");
  const keyDocClean = unmaskDocument(keyDocument);

  return accountDocClean.includes(keyDocClean)
    ? claimTypes.PORTABILITY
    : claimTypes.KEY_TRANSFER;
}

const keyTypeMap = {
  "E-mail": "email",
  Celular: "cellPhoneNumber",
};

const NewKey = () => {
  const { filterState } = usePixKeysContext();
  const isMobile = Hooks.useMediaQuery("mobile");
  const { checkPixKey } = Validator;
  const { account, currentAccountId } = useAccountStore();
  const { user } = useUserStore();
  const { print } = usePrint();
  const { data: keysList = [] } = useGetKeys();
  const deviceFingerprint = useDeviceFingerprint();
  const navigate = useNavigate();
  const routes = getPixRoutesData();
  const { getValue, updateValues, removeValue } = useSessionStorage();
  const [currentPage, setCurrentPage] = useState<Page>(PAGES.SELECT_KEY);

  const setRequest = setCreateKey;
  const request = getCreateKey();
  const keys = getPixKey();
  const defaultKey = keys?.find((key) => key.default) || keys[0]?.key;
  const [modal, setModal] = useState<{
    open: boolean;
    content?: JSX.Element;
  }>({
    open: false,
  });

  const [drawer, setDrawer] = useState<{
    title: string;
    open: boolean;
    content: JSX.Element;
    onClose?: () => void;
  } | null>(null);

  const newPixKey = filterState.newPixKey;
  const keyType = Validator.checkPixKey(filterState.newPixKey);
  const isExternalCode = ["E-mail", "Celular"].includes(keyType);

  const onSendToken = async () => {
    const isClaimRequest = !!getValue("claimType");

    const formattedPixKey =
      keyType === "Celular" ? formatPhoneNumber(newPixKey) : newPixKey;

    const tokenTypeId = isClaimRequest ? createPixClaimId : tokenRequestGuid;

    await pixService.sendVerifyingToken({
      key: formattedPixKey,
      tokenTypeId,
      keyType: keyTypeMap[keyType],
    });
  };

  const handleSubmitInTokenSheet = (token: string) => {
    try {
      const claimType = getValue("claimType") as ClaimType;
      const deviceId = getValue("deviceFingerprint");
      if (claimType) {
        createTokenRequestClaim({
          claimType,
          token,
        });
      } else {
        createPixKeyRequest(token, deviceId);
      }
    } catch (error) {
      const friendlyMessage =
        error?.response?.data?.errors?.[0]?.friendlyMessage;
      toast({
        variant: "error",
        title: friendlyMessage || "Algo deu errado",
      });
    }
  };

  const goToToken = useCallback(async () => {
    if (isExternalCode) {
      onSendToken();
    }

    setDrawer({
      title: isExternalCode ? keyType : "Token",
      content: (
        <TokenSheet
          isExternalCode={isExternalCode}
          keyType={keyType}
          newPixKey={newPixKey}
          goToNextStep={goToSucessPage}
          onSendToken={onSendToken}
          openClaimDrawer={openClaimDrawer}
          onSubmit={handleSubmitInTokenSheet}
        />
      ),
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, navigate, routes.token, isExternalCode, keyType, newPixKey]);

  const pixType = formattedKeyTypes[keyType] ?? "evp";
  const formattedPixKey =
    keyType === "Celular"
      ? `+55${formatPhoneNumber(newPixKey)}`
      : formatKeyTypes(newPixKey, keyType);

  const createTokenRequestClaim = useCallback(
    async ({ claimType, token }: { claimType: ClaimType; token: string }) => {
      const claimRequest: {
        claimType: string;
        type: string;
        key: string;
        deviceId: string;
        token: string;
      } = {
        type: request?.type,
        claimType,
        key:
          request?.type === "NationalRegistration"
            ? formattedPixKey.replace(/[^a-zA-Z0-9]/g, "")
            : formattedPixKey,
        deviceId: deviceFingerprint?.deviceId,
        token,
      };

      try {
        await API.pix.post("/api/v1/pix/Claims", claimRequest);
        goToSucessPage();
        removeValue("claimType");
      } catch (error) {
        console.error("Error creating token request claim:", error);
        const friendlyMessage =
          error.response?.data?.errors?.[0]?.friendlyMessage ||
          "Tente novamente mais tarde.";
        toast({
          title: "Erro ao reivindicar chave",
          description: friendlyMessage,
          variant: "error",
        });
      }
    },
    [request?.type, formattedPixKey, deviceFingerprint?.deviceId, removeValue],
  );

  const backToInsertKey = useCallback(() => {
    navigate(routes.pixMyKeysNew);
  }, [navigate, routes.pixMyKeysNew]);

  const createTokenRequest = useCallback(async () => {
    const cleanKey =
      request?.type === "PHONE" || request?.type === "EMAIL"
        ? request.type === "PHONE"
          ? request?.key?.replace(/\D/g, "")
          : request?.key
        : undefined;

    const path = isMobile ? routes.pixMyKeysNew : `${routes.pix}?newKey=open`;

    setTokenData({
      request: {
        guid_token_type: request?.claim
          ? tokenRequestClaimGuid
          : tokenRequestGuid,
        guid_user: user?.userId,
        customKey: request?.claim ? undefined : cleanKey,
        path,
        header: {
          title: request?.title,
          leftIcon: {
            name: "ArrowArrowNoLineLeft",
            onClick: backToInsertKey,
          },
        },
      },
    });

    goToToken();
  }, [
    backToInsertKey,
    isMobile,
    request,
    user?.userId,
    goToToken,
    routes.pixMyKeysNew,
    routes.pix,
  ]);

  const openClaimDrawer = useCallback(
    async (type: ClaimType) => {
      console.log("openClaimDrawer", type);
      setDrawer({
        title: `${
          type === claimTypes.KEY_TRANSFER ? "Reivindicação" : "Portabilidade"
        } de chave Pix`,
        open: true,
        content: (
          <S.WrapperClaimDrawer>
            <div>
              <Icon name="MoneyPix" />
              <p>
                <span>{checkPixKey(newPixKey)}</span>
                <span>{formattedPixKey}</span>
              </p>
            </div>
            {type === claimTypes.KEY_TRANSFER ? (
              <p>
                Chave Pix já cadastrada por outra pessoa, deseja iniciar
                processo de reivindicação?
              </p>
            ) : (
              <p>
                Chave Pix já está cadastrada em outra instituição, deseja fazer
                portabilidade?
              </p>
            )}
            <Button onClick={createTokenRequest}>
              {type === claimTypes.KEY_TRANSFER
                ? "Iniciar Reivindicação"
                : "Fazer Portabilidade"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                goToSelectKeys();
                setDrawer(null);
              }}
            >
              Cadastrar outra
            </Button>
          </S.WrapperClaimDrawer>
        ),
      });
    },
    [checkPixKey, newPixKey, formattedPixKey, createTokenRequest],
  );

  const closeModal = () => setModal({ ...modal, open: false });

  const closeDrawer = () => {
    setDrawer((state) => ({
      ...state,
      open: false,
    }));
    removeValue("claimType");
  };

  const openImageQrModal = () => {
    closeDrawer();

    setModal({
      open: true,
      content: (
        <ContentQR defaultKey={defaultKey as never} closeModal={closeModal} />
      ),
    });
  };

  const copyDefaultKey = () =>
    navigator.clipboard.writeText(defaultKey as never);

  const openSharedDrawer = () => {
    if (isMobile) {
      setDrawer({
        title: "Compartilhar QR Code",
        open: true,
        content: (
          <SharedDrawer
            openImageQrModal={openImageQrModal}
            copyDefaultKey={copyDefaultKey}
          />
        ),
      });
    } else {
      print({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        content: <PrintableQRCode defaultKey={defaultKey} />,
      });
    }
  };

  const backToMyKeys = useCallback(() => {
    navigate(routes.pixMyKeys);
  }, [navigate, routes.pixMyKeys]);

  const backToMyKeysSucess = () => {
    setRequest({});
    setTokenData({});
    backToMyKeys();
    removeValue("claimType");
  };

  const goToSelectKeys = () => {
    setCurrentPage(PAGES.SELECT_KEY);
  };

  const goToInsertKey = () => {
    setCurrentPage(PAGES.INSERT_KEY);
  };

  const goToSucessPage = () => {
    setDrawer(null);
    setCurrentPage(PAGES.SUCCESS_SCREEN);
  };

  useEffect(() => {
    if (!isMobile) return;
    setHeader({
      title: "Minhas chaves Pix",
      show: isMobile,
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: backToMyKeys,
      },
    });
  }, [backToMyKeys, isMobile]);

  async function createPixKeyRequest(token: string, deviceId: string) {
    try {
      const request: CreatePixKeyRequest = {
        type: pixType,
        token,
        deviceId,
        key: isExternalCode ? formattedPixKey : undefined,
      };

      await pixService.createPixKey(request);
      goToSucessPage();
    } catch (error) {
      const friendlyMessage =
        error?.response?.data?.errors?.[0]?.friendlyMessage;
      toast({
        variant: "error",
        title: friendlyMessage || "Algo deu errado",
      });
    }
  }

  async function handleVerification() {
    try {
      const keyResponse = await checkPixKeyExists(
        formattedPixKey,
        currentAccountId,
      );

      if (!keyResponse) {
        await createTokenRequest();
        return;
      }
      const keyAlreadyCreated = keysList.find(
        (key) => key.key === formattedPixKey,
      );

      if (keyAlreadyCreated) {
        toast({
          variant: "error",
          title: "Chave já cadastrada para esse usuário",
        });
        return;
      }

      const currentDocument =
        account?.personInfoResponse?.document ||
        account?.companyInfoResponse?.document;

      const claimType = determineClaimType(
        currentDocument,
        keyResponse.documentMask,
      );

      openClaimDrawer(claimType);
      updateValues({ claimType });
    } catch (error) {
      const friendlyMessage =
        error?.response?.data?.errors?.[0]?.friendlyMessage;

      toast({
        variant: "error",
        title: friendlyMessage || "Algo deu errado",
      });
    } finally {
      globalQueryClient.invalidateQueries({
        queryKey: queries.pixKeys.getKeys({ accountId: currentAccountId }),
      });
    }
  }

  const components = {
    [PAGES.SUCCESS_SCREEN]: (
      <SuccessScreen
        claimType={getValue("claimType") as ClaimType}
        goToSelectKeys={goToSelectKeys}
        openSharedDrawer={openSharedDrawer}
        backToMyKeysSucess={backToMyKeysSucess}
      />
    ),
    [PAGES.INSERT_KEY]: (
      <InsertKey
        backToMyKeysSucess={backToMyKeysSucess}
        createTokenRequest={handleVerification}
      />
    ),
    [PAGES.SELECT_KEY]: (
      <SelectKey
        keys={keys}
        goToInsertKey={goToInsertKey}
        goToToken={goToToken}
      />
    ),
  };

  return (
    <>
      {components[currentPage]}
      {modal.open ? modal?.content : null}
      <Drawer
        open={drawer?.open ?? false}
        title={drawer?.title}
        onDismiss={() => {
          closeDrawer();
          drawer.onClose?.();
        }}
        position="right"
      >
        {drawer?.content}
      </Drawer>
    </>
  );
};

export default NewKey;
