import { MyKeysResponse } from "@/utils/types";
import { RequestedClaimApiResponse } from "./types";

export function filterRequestedClaims(data: RequestedClaimApiResponse) {
  if (!data?.items?.length) return [];

  try {
    const groupedClaims = groupClaimsByKey(data.items);
    return processGroups(groupedClaims);
  } catch (error) {
    console.error("Error in filterRequestedClaims:", { error, data });
    throw error;
  }
}

function groupClaimsByKey(
  items: RequestedClaimApiResponse["items"],
): Map<string, RequestedClaimApiResponse["items"]> {
  return items.reduce((acc, item) => {
    const key = item.claim.key;
    if (!acc.has(key)) acc.set(key, []);
    acc.get(key)!.push(item);
    return acc;
  }, new Map<string, RequestedClaimApiResponse["items"]>());
}

function processGroups(
  groupedClaims: Map<string, RequestedClaimApiResponse["items"]>,
) {
  const todayTimestamp = Date.now();

  return Array.from(groupedClaims.values()).flatMap((group) => {
    const waitingGrantorItems = sortByLastModified(
      group.filter((item) => item.claim.claimStatus === "WAITING_GRANTOR"),
    );

    if (!waitingGrantorItems.length) return [];

    const mostRecentWaitingGrantor = waitingGrantorItems[0];
    const waitingGrantorTimestamp = new Date(
      mostRecentWaitingGrantor.claim.lastModified,
    ).getTime();

    const mostRecentOtherStatus = sortByLastModified(
      group.filter((item) =>
        ["COMPLETED", "CANCELLED"].includes(item.claim.claimStatus),
      ),
    )[0];

    if (
      mostRecentOtherStatus &&
      new Date(mostRecentOtherStatus.claim.lastModified).getTime() >
        waitingGrantorTimestamp
    ) {
      return [];
    }

    return new Date(mostRecentWaitingGrantor.claim.grantorDeadline).getTime() >=
      todayTimestamp
      ? [mostRecentWaitingGrantor]
      : [];
  });
}

function sortByLastModified(
  items: RequestedClaimApiResponse["items"],
): RequestedClaimApiResponse["items"] {
  return [...items].sort(
    (a, b) =>
      new Date(b.claim.lastModified).getTime() -
      new Date(a.claim.lastModified).getTime(),
  );
}

export function filterReceivedClaims(data: MyKeysResponse[]) {
  try {
    return data?.filter((key) => key.keyStatus === "WAITING_RESOLUTION");
  } catch (error) {
    console.error("Error filtering claims:", error);
    return [];
  }
}
