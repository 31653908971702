import { MyKeysResponse } from "@/utils/types";
import { Card, Chips } from "@hyperlocal/vital";
import Icon, { IconProps } from "@hyperlocal/vital-icons";

export type ReceivedClaimsListProps = {
  claims: MyKeysResponse[];
  checkPixKey: (key: string) => string;
  openReceivedClaimDrawer: (claim: MyKeysResponse) => void;
  getIconByKeyType: (keyType: string) => IconProps["name"];
};

export function ReceivedClaimsList({
  openReceivedClaimDrawer,
  claims,
  checkPixKey,
  getIconByKeyType,
}: ReceivedClaimsListProps) {
  if (!claims.length) return null;
  return (
    <>
      <h3>Recebidas</h3>
      {claims.map((claim) => (
        <nav key={claim?.key} className="claim">
          <Card onClick={() => openReceivedClaimDrawer(claim)}>
            <div>
              <Icon
                className="keyTypeIcon"
                name={getIconByKeyType(claim?.keyType.toUpperCase())}
              />
              <p>
                <span>
                  {claim?.keyType === "PhoneNumber"
                    ? checkPixKey(claim?.key?.replace("+55", ""))
                    : checkPixKey(claim?.key)}
                </span>
                <span>
                  {" "}
                  {claim?.key.length > 23
                    ? `${claim?.key.substring(0, 20)}...`
                    : claim?.key}
                </span>
              </p>
            </div>
            <Chips variant="tag" color="warning">
              Pendente
            </Chips>
          </Card>
        </nav>
      ))}
    </>
  );
}
