import { Card, Chips } from "@hyperlocal/vital";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { ClaimItem, RequestedClaimApiResponse } from "./types";

type ClaimListProps = {
  openRequestedClaimDrawer: (claim: ClaimItem) => void;
  checkPixKey: (key: string) => string;
  claims: RequestedClaimApiResponse["items"];
  getIconByKeyType: (keyType: string) => IconProps["name"];
};

export function RequestedClaimsList({
  openRequestedClaimDrawer,
  claims,
  checkPixKey,
  getIconByKeyType,
}: ClaimListProps) {
  if (!claims.length) return null;
  return (
    <>
      <h3>Solicitadas</h3>
      {claims.map((claim) => (
        <nav key={claim.claim.key} className="claim">
          <Card onClick={() => openRequestedClaimDrawer(claim)}>
            <div>
              <Icon
                className="keyTypeIcon"
                name={getIconByKeyType(claim.claim.keyType.toUpperCase())}
              />
              <p>
                <span>
                  {claim.claim.keyType === "PhoneNumber"
                    ? checkPixKey(claim.claim.key.replace("+55", ""))
                    : checkPixKey(claim.claim.key)}
                </span>
                <span>
                  {claim.claim.key.length > 23
                    ? `${claim.claim.key.substring(0, 20)}...`
                    : claim.claim.key}
                </span>
              </p>
            </div>
            <Chips variant="tag" color="warning">
              Pendente
            </Chips>
          </Card>
        </nav>
      ))}
    </>
  );
}
